/* Hotwire's version of data-disable-with */
button .when-disabled {
    display: none;
}
button[disabled] .when-disabled {
    display: initial;
}
button .when-enabled {
    display: initial;
}
button[disabled] .when-enabled {
    display: none;
}

@layer components {
    .btn {
        display: inline-flex;
        padding: 8px 12px;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        &:hover,
        &:focus {
            cursor: pointer;
        }

        &:focus-visible {
            outline: 2px solid var(--base-border-focus);
            outline-offset: 2px;
        }

        &:disabled {
            opacity: 60%;
            cursor: not-allowed;
        }
    }

    .btn-outline {
        box-shadow: 0 0 #0000;
    }

    .btn-xs {
        padding: 4px 8px;
        font-size: 12px;
        line-height: 1;
    }

    .btn-sm,
    .btn-small {
        padding: 4px 8px;
    }

    .btn-lg,
    .btn-large {
        padding: 10px 14px;
    }

    .btn-xl {
        padding: 12px 16px;
    }

    .btn-icon {
        padding: 8px;
        border-radius: 50%;

        &.btn-xs {
            padding: 4px;
        }

        &.btn-sm {
            padding: 10px;
        }

        &.btn-lg {
            padding: 12px;
        }

        &.btn-xl {
            padding: 14px;
        }
    }

    .btn-expanded,
    .btn-block {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .btn-primary {
        background: var(--bg-primary);
        color: var(--text-on-primary);

        &:hover {
            background: var(--bg-primary-hover);
            color: var(--text-on-primary);
        }

        &.btn-outline {
            background: transparent;
            color: var(--text-primary);
            border: 1px solid var(--border-primary-hover);

            &:hover {
                background: var(--bg-primary-hover);
                color: var(--text-on-primary);
                border: 1px solid var(--bg-primary-hover);
            }
        }
    }

    .btn-secondary,
    .btn-tertiary {
        background: var(--bg-secondary);
        color: var(--text-on-secondary);
        border: 1px solid var(--border-secondary);

        &:hover {
            background: var(--bg-secondary-hover);
            color: var(--text-on-secondary-hover);
        }

        &.btn-outline {
            background: transparent;
            color: var(
                --text-secondary
            ); /* Changed from var(--text-on-secondary) */
            border: 1px solid var(--border-secondary);

            &:hover {
                background: var(--bg-secondary-hover);
                color: var(--text-on-secondary-hover);
            }
        }
    }

    .btn-danger {
        background: var(--bg-danger);
        color: var(--text-on-danger);

        &:hover {
            background: var(--bg-danger-hover);
            color: var(--text-on-danger);
        }

        &:focus-visible {
            outline: 2px solid var(--border-danger-focus);
            outline-offset: 2px;
        }

        &.btn-outline {
            background: transparent;
            color: var(--text-danger);
            border: 1px solid var(--border-danger);

            &:hover {
                background: var(--bg-danger-hover);
                color: var(--text-on-danger);
                border: 1px solid var(--bg-danger-hover);
            }
        }
    }

    .btn-success {
        background: var(--bg-success);
        color: var(--text-on-success);

        &:hover {
            background: var(--bg-success-hover);
            color: var(--text-on-success);
        }

        &:focus-visible {
            outline: 2px solid var(--border-success-focus);
            outline-offset: 2px;
        }

        &.btn-outline {
            background: transparent;
            color: var(--text-success);
            border: 1px solid var(--border-success);

            &:hover {
                background: var(--bg-success-hover);
                color: var(--text-on-success);
                border: 1px solid var(--bg-success-hover);
            }
        }
    }

    .btn-light,
    .btn-white,
    .btn-gray {
        background: var(--bg-light);
        color: var(--text-on-light);
        border: 1px solid var(--border-light);

        &:hover {
            background: var(--bg-light-hover);
            color: var(--text-on-light);
        }

        &.btn-outline {
            background: transparent;
            color: var(--text-on-light-outline);
            border: 1px solid var(--border-light);

            &:hover {
                background: var(--bg-light-hover);
                color: var(--text-on-light);
            }
        }
    }

    .btn-dark {
        background: var(--bg-dark);
        color: var(--text-on-dark);
        border: 1px solid var(--bg-dark);

        &:hover {
            background: var(--bg-dark-hover);
            color: var(--text-on-dark);
            border: 1px solid var(--bg-dark-hover);
        }

        &:focus-visible {
            outline: 2px solid var(--border-dark-focus);
            outline-offset: 2px;
        }

        &.btn-outline {
            background: transparent;
            color: var(--text-on-dark-outline);
            border: 1px solid var(--border-dark);

            &:hover {
                background: var(--bg-dark-hover);
                color: var(--text-on-dark);
            }
        }
    }

    .btn-link {
        background: transparent;
        color: var(--text-primary);
        box-shadow: 0 0 #0000;

        &:hover {
            color: var(--text-primary-hover);
        }

        &.btn-icon {
            background: var(--base-bg-lowest);
            border: 1px solid var(--border-primary-hover);

            &:hover {
                background: var(--base-bg-low);
            }
        }
    }
}
