nav.pagy.nav {
  @apply inline-flex flex-wrap items-center justify-start;

  a:first-child {
    @apply rounded-tl rounded-bl;
  }

  a:last-child {
    @apply rounded-tr rounded-br;
  }

  a:not(.gap) {
    @apply border relative block leading-tight bg-white text-primary-500 no-underline py-2 px-3 tabular-nums;
    @apply dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600;
    margin-left: -1px;

    &:hover {
      @apply bg-gray-200 dark:bg-gray-700;
    }

    &[aria-current="page"].current {
      @apply z-10 border-primary-500 bg-primary-500 text-white;
      @apply dark:bg-primary-800 dark:text-gray-200 dark:border-primary-600;
      margin-left: -1px;
    }

    &:not([href]) {
      @apply bg-white text-gray-500;
      @apply dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600;
      margin-left: -1px;
    }
  }
}
